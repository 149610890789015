<template>
  <Head>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png">
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png">
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png">
      <link rel="manifest" href="/favicon/site.webmanifest">
    </Head>
  <Html :lang="computedLocale"/>


  <slot/>
<LandingFooter/>
</template>
<script setup lang="ts">
const { locale } = useI18n()

const computedLocale = computed(() => locale.value)

useHead({
  titleTemplate: 'Flexi-solutions - %s',
  script: [
    {
      id: 'cookieyes',
      type: 'text/javascript',
      src: 'https://cdn-cookieyes.com/client_data/1b4552b306f28cbfc35f04f8/script.js',
      tagPosition: 'bodyClose'
    }
  ]
})

const colorMode = useColorMode()
colorMode.preference = 'light'


</script>

<style>
html {
  scroll-behavior: smooth;
}
/*
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.5rem);


} */
</style>