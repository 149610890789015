<template>
  <button
    class="rounded-full totop"
    @click="scrollToTop"
    :class="{ visible: showButton }"
  >
    <icon name="mdi:arrow-top-bold-circle-outline" size="60px" />
  </button>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useScroll, useWindowScroll } from "@vueuse/core";

const showButton = ref(false);
const { y } = useWindowScroll();

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

// Show button when scrolled down 200px
watch(y, (newY) => {
  showButton.value = newY > 200;
});
</script>

<style scoped>
button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 5px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

button.visible {
  opacity: 1;
}
</style>
